.Money {
  margin-top: 140px;
  @media (max-width: 1020px) {
    margin-top: 70px;
  }
  .title {
    margin-bottom: 20px;
    font-size: 64px;
    text-align: center;
    @media (max-width: 1020px) {
      font-size: 50px;
      margin-bottom: 30px;
    }
    @media (max-width: 700px) {
      font-size: 24px;
    }
  }
  .subtitle {
    margin-bottom: 60px;
    font-size: 20px;
    text-align: center;
    color: #6a6a6a;
    font-weight: 400;
  }
  .wrapper {
    width: 100%;
    display: flex;
    gap: 30px;
    @media (max-width: 1300px) {
      flex-direction: column;
      align-items: center;
    }
  }
  .card {
    padding: 30px;
    width: calc(50% - 15px);
    display: flex;
    flex-direction: column;
    gap: 20px;
    border: none;
    box-shadow: 0 6px 25px -10px rgba(0,0,0,0.75);
    background-color: white;
    border-radius: 24px;
    @media (max-width: 1300px) {
      width: 100%;
      max-width: 900px;
    }
    @media (max-width: 1300px) {
      padding: 15px;
    }
    .top {
      display: flex;
      align-items: center;
      gap: 20px;
      .icon {
        width: 50px;
        height: 50px;
        min-width: 50px;
        min-height: 50px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 20px;
        border: 2px solid #bdbdbd;
      }
      .text {
        font-size: 32px;
        @media (max-width: 700px) {
          font-size: 24px;
        }
      }
    }
    .middle {
      font-size: 20px;
      color: #6a6a6a;
      font-weight: 400;
      @media (max-width: 700px) {
        font-size: 16px;
      }
    }
  }
  .card__content {
    height: 170px;
    display: flex;
    flex-wrap: wrap;
    gap: 30px;
    @media (max-width: 700px) {
      display: none;
    }
    &.second_content {
      gap: 15px;
    }
    .container_f {
      width: 100%;
      display: flex;
      justify-content: space-between;
      &.icons {
        justify-content: center;
        gap: 40px;
      }
    }
    .item_f {
      height: 70px;
      width: 270px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 20px;
      border: 2px solid #bdbdbd;
      overflow: hidden;
    }
    .item_s {
      padding: 0 5px;
      height: 70px;
      width: calc(25% - 11.25px);
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      border-radius: 20px;
      border: 2px solid #bdbdbd;
      overflow: hidden;
      .picture {
        width: 30px;
        height: 30px;
        min-width: 30px;
        min-height: 30px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
      .label {
        font-size: 14px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 400;
      }
    }
  }
  .circle {
    position: relative;
    width: 50px;
    height: 50px;
    border-radius: 25px;
    border: 2px solid black;
    display: flex;
    justify-content: center;
    align-items: center;
    svg:first-child {
      position: absolute;
      right: 200%;
      bottom: 30%;
    }
    svg:last-child {
      position: absolute;
      left: 170%;
      bottom: 35%;
    }
    path {
      fill: black;
    }
  }
}

[data-theme="dark"] {
  .Money {
    .card {
      border: 1px solid #272727;
      box-shadow: none;
      background-color: transparent;
    }
    .circle {
      border-color: white;
      path {
        fill: white;
      }
    }
  }
}